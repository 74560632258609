import CheckList from '../FormikMuiFields/CheckList'
import DateInput from '../FormikMuiFields/DateInput'
import RadioInput from '../FormikMuiFields/RadioInput'
import SelectInput from '../FormikMuiFields/SelectInput'
import MultiDropdown from '../FormikMuiFields/MultiDropdown'
import TextInput from '../FormikMuiFields/TextInput'
import { Question } from '../Questionnaire/types'
export interface JSONFormFieldProps {
  question: Question
  error: boolean | undefined
}

const JSONFormField: React.FC<JSONFormFieldProps> = ({ question, error }) => {
  if (question.type === 'text') {
    return (
      <TextInput
        name={question.name}
        // label={question.name}
        autoComplete={'off'}
        placeholder={question.placeholder}
        error={error}
        required={question.mandatory}
        variant="standard"
      />
    )
  } else if (question.type === 'number') {
    return (
      <TextInput
        name={question.name}
        // label={question.name}
        autoComplete={'off'}
        type={'number'}
        onKeyPress={(event) => {
          if (['e', 'E', '+', '-'].includes(event.key)) {
            event.preventDefault()
          }
        }}
        inputProps={{ min: 0 }}
        placeholder={question.placeholder}
        error={error}
        required={question.mandatory}
        variant="standard"
      />
    )
  } else if (question.type === 'textarea') {
    return (
      <TextInput
        name={question.name}
        // label={question.name}
        placeholder={question.placeholder}
        autoComplete="off"
        multiline={true}
        rows={4}
        error={error}
        required={question.mandatory}
      />
    )
  } else if (question.type === 'date') {
    return (
      <DateInput
        name={question.name}
        label={''}
        placeholder={question.placeholder}
        error={error ? error : false}
        required={question.mandatory}
      />
    )
  } else if (question.type === 'checklist') {
    return (
      <CheckList
        style={{ maxHeight: '800px', overflow: 'auto' }}
        name={question.name}
        items={question.options}
        error={error ? error : false}
        searchable
      />
    )
  } else if (question.type === 'select') {
    return (
      <SelectInput
        name={question.name}
        label={''}
        items={question.options}
        error={error ? error : false}
        fullWidth={true}
        placeholder={question.placeholder}
        required={false}
        variant="standard"
      />
    )
  } else if (question.type === 'multiselect') {
    return (
      <MultiDropdown
        name={question.name}
        label={''}
        placeholder={question.placeholder}
        items={question.options}
        error={error ? error : false}
        fullWidth={true}
        // required={question.mandatory}
        variant="standard"
      />
    )
  } else if (question.type === 'radio') {
    return <RadioInput name={question.name} items={question.options} />
  } else {
    return <></>
  }
}

export default JSONFormField
