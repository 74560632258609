import React, { ReactNode, useEffect, useState } from 'react'
import { Field, ErrorMessage, FieldInputProps } from 'formik'
import SearchIcon from '@material-ui/icons/Search'
import {
  Checkbox,
  CheckboxProps,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  formControl: {
    width: '100%',
  },
  itemList: { maxHeight: '220px', overflow: 'auto' },
  item: {
    display: 'flex',
  },
  chip: {
    margin: '2px',
  },
}))

export interface CheckListItem {
  label: string
  value: string
}

interface Props {
  name: string
  items: CheckListItem[]
  error?: boolean
  searchable?: boolean
}

interface CheckListMuiProps extends FieldInputProps<string[]> {
  children: ReactNode
  errorText: string
  items: CheckListItem[]
  filteredItems: CheckListItem[]
  error?: boolean
}

const CheckListMui: React.FC<CheckListMuiProps> = ({
  children,
  items,
  filteredItems,
  value,
  name,
  onChange,
  onBlur,
  errorText,
  error,
}) => {
  const classes = useStyles()
  return (
    <>
      {value && (
        <div style={{ marginBottom: '2px' }}>
          {items.map((i) =>
            value.includes(i.value) ? (
              <Chip color="primary" label={i.label} className={classes.chip} />
            ) : (
              ''
            ),
          )}
        </div>
      )}
      <FormControl component="fieldset" error={error} className={classes.formControl}>
        <FormGroup onChange={onChange} onBlur={onBlur}>
          <div className={classes.itemList}>
            {filteredItems.map((item: CheckListItem) => (
              <FormControlLabel
                className={classes.item}
                value={item.value}
                control={
                  <Checkbox
                    value={item.value}
                    checked={value ? value.includes(item.value) : false}
                    name={name}
                    color="primary"
                  />
                }
                label={item.label}
                key={item.value}
              />
            ))}
          </div>
        </FormGroup>
        <FormHelperText>{error ? errorText : ''}</FormHelperText>
      </FormControl>
    </>
  )
}

const CheckList: React.FC<Props & CheckboxProps> = ({
  name,
  items,
  error = false,
  searchable = false,
  ...rest
}) => {
  const [filteredItems, setFilteredItems] = useState<CheckListItem[]>(items)
  const classes = useStyles()
  searchable = searchable && items.length > 5
  useEffect(() => {
    setFilteredItems(items)
  }, [items])
  return (
    <>
      {searchable && (
        <div className={classes.searchIcon}>
          <TextField
            label=""
            placeholder="Search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color={'disabled'} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilteredItems(() => {
                return items.filter((item) => {
                  return item.label.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0
                })
              })
            }}
          />
        </div>
      )}
      <Field
        as={CheckListMui}
        name={name}
        error={error}
        items={items}
        filteredItems={filteredItems}
        errorText={<ErrorMessage name={name} />}
        {...rest}
      />
    </>
  )
}

export default CheckList
