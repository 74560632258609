import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import noActivities from '../../assets/no_activities.svg'
import theme from '../../Styles/theme'
import { Card, Paper } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    flexDirection: 'column',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  label: {
    marginTop: theme.spacing(1),
  },
})

interface Props {}

const NoData: React.FC<Props> = ({}) => {
  const classes = useStyles()

  return (
    <Paper elevation={0}>
      <div className={classes.root}>
        <img src={noActivities} alt="" />
        <div className={classes.label}>No Activities to display.</div>
        <div className={classes.label}>Add/Select locations to view activities.</div>
      </div>
    </Paper>
  )
}

export default NoData
