import { StepWizardActionTypes } from '../../Types/stepWizardTypes'
import { SlideProps, Question, initialVals } from './types'

export const getActiveQuestion = (questionList: Question[]) => {
  const activeQuestion: Question[] = questionList.filter((question) => question.active)

  return activeQuestion.length === 0
    ? {
        type: '',
        mandatory: false,
        question: '',
        name: '',
        placeholder: '',
        id: 0,
        value: '',
        options: [],
        quick_fill: null,
        title: '',
        description: '',
        readmore: '',
        help_text: '',
      }
    : activeQuestion[0]
}

export const appendAndMoveNext = (
  newQuestions: any,
  activeQuestion: Question,
  dispatch: React.Dispatch<StepWizardActionTypes>,
  values: initialVals,
  setSlideIn: React.Dispatch<React.SetStateAction<boolean>>,
  setSlideDirection: React.Dispatch<React.SetStateAction<SlideProps>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  removeNextQuestions: boolean,
) => {
  const nextQuestion: Question[] = newQuestions.map((question: Question) => {
    return {
      type: question.type ? question.type : 'text',
      mandatory: question.mandatory ? question.mandatory : false,
      question: question.question,
      name: question.name ? question.name : question.question.split(' ').join('_'),
      placeholder: question.placeholder ? question.placeholder : '',
      id: question.id,
      value: question.value
        ? question.value
        : ['multiselect', 'checklist'].includes(question.type)
        ? []
        : '',
      options: question.options ? question.options : [],
      quick_fill: question.quick_fill,
      title: question.title,
      description: question.description,
      readmore: question.readmore,
      help_text: question.help_text,
    }
  })
  setIsLoading(false)
  setSlideIn(false)
  setSlideDirection('right')
  setTimeout(() => {
    setSlideDirection('left')
    setSlideIn(true)
    dispatch({
      type: 'NEXT_QUESTION',
      payload: {
        nextQuestions: nextQuestion,
        removeNextQuestions: removeNextQuestions,
        submittedVals: values,
      },
    })
  }, 500)
}

export const isActiveQuestionLast = (questions: Question[]) => {
  const activeIndex: number = questions.findIndex((question) => question.active)
  if (activeIndex + 1 === questions.length) {
    return true
  }
  return false
}
