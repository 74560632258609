import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  header: string | JSX.Element
  isReviewCard?: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '550px',
    background: '#FFFFFF',
    borderRadius: '4px',
    marginTop: '20px',
  },
  childrenContainer: {
    padding: '30px',
  },
  headerContainer: {
    padding: '20px',
    borderBottom: '1px solid #eee',
  },
  headerText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    fontFamily: 'Nunito',
  },
}))

const FormCard: React.FC<Props> = ({ children, isReviewCard, header }) => {
  const classes = useStyles()

  return (
    <div className={classes.container} style={{ minWidth: isReviewCard ? '100%' : '550px' }}>
      <div className={classes.headerContainer}>
        <h3 className={classes.headerText}>{header}</h3>
      </div>
      <div
        className={classes.childrenContainer}
        style={{ padding: isReviewCard ? '20px' : '30px' }}>
        {children}
      </div>
    </div>
  )
}

export default FormCard
