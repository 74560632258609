import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form, Formik } from 'formik'
import IconButton from '@material-ui/core/IconButton/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import * as yup from 'yup'
import Typography from '@material-ui/core/Typography'
import SelectInput from '../../Components/FormikMuiFields/SelectInput'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { useContext, useState } from 'react'
import AddUser from '../AddUser'
import { StepWizardContext } from '../OnboardingSteps'
import { FetchContext } from '../../Context/FetchContext'
import toast from 'react-hot-toast'
import LoadingButton from '../LoadingButton'
import { ActivityMapping } from '../../Types/activityMapping'
import CenterContent from '../CenterContent'
import ToolTip from '../ToolTip'
import { getUsersByRole } from '../../util/user'
import { AuthContext } from '../../Context/AuthContext'
import UserOnboardPrompt from '../UserOnboardPrompt'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    addButton: {
      padding: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
)

let validationSchema = yup.object().shape({
  tracker_admin: yup.string().required('Tracker Owner is a required field'),
  tracker_manager: yup.string().required('Tracker Manager is a required field'),
})

interface AssignUserProps {
  openState: boolean
  handlesDialogClose: () => void
  selectedActivities: (string | number)[]
  setSelectedActivities: (value: React.SetStateAction<React.ReactText[]>) => void
  showOnlyUnassigned: boolean
  lawarea?: string
}

export interface AddUserDialogStateProps {
  openState: boolean
  role: string
  email?: string
}
export interface UserItem {
  value: string
  label: string
}
interface UserProps {
  tracker_admin: string
  tracker_manager: string
}

const AssignUser: React.FC<AssignUserProps> = ({
  openState,
  handlesDialogClose,
  selectedActivities,
  setSelectedActivities,
  showOnlyUnassigned,
  lawarea = '',
}) => {
  const { globalState, dispatch } = useContext(StepWizardContext)
  const authContext = useContext(AuthContext)
  const fetchContext = useContext(FetchContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userOnboardPromptState, setUserOnboardPromptState] = useState<boolean>(false)
  const ownerList: UserItem[] = getUsersByRole(globalState.users, 'tracker_admin')
  const managerList: UserItem[] = getUsersByRole(globalState.users, 'tracker_manager')
  const [addUserDialogState, setAddUserDialogState] = useState<AddUserDialogStateProps>({
    openState: false,
    role: '',
  })
  let initialVals: UserProps = {
    tracker_admin: '',
    tracker_manager: '',
  }
  const classes = useStyles()
  const handleAddUserDialogClose = () => {
    setAddUserDialogState({
      openState: false,
      role: '',
    })
  }
  const handleOnobardUserClose = () => {
    setUserOnboardPromptState(false)
  }
  const handleAddUserDialogOpen = (role: string) => {
    setAddUserDialogState({
      openState: true,
      role: role,
    })
  }
  return (
    <>
      <Dialog open={openState} onClose={handlesDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">
            <CenterContent>
              Assign Users <ToolTip id="assignUsers" activeStepIndex={4} />
            </CenterContent>
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handlesDialogClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={initialVals}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setIsLoading(true)
              let activityMapping: ActivityMapping = {}
              selectedActivities.forEach((id) => {
                let UAN: string, rest: string[]
                ;[UAN, ...rest] = id.toString().split('_')
                const locationName: string = rest.join('_')
                if (locationName in activityMapping) {
                  activityMapping[locationName] = {
                    ...activityMapping[locationName],
                    [UAN]: values,
                  }
                } else {
                  activityMapping[locationName] = {
                    [UAN]: values,
                  }
                }
              })

              let tempMapping: ActivityMapping = globalState.activityMapping
              let oldMapping: ActivityMapping = globalState.activityMapping
              const isUserExist = globalState.users.some(
                (u) => u.email === authContext.authState.userInfo.email,
              )
              for (let location in activityMapping) {
                if (location in tempMapping) {
                  for (let UAN in activityMapping[location]) {
                    tempMapping = {
                      ...tempMapping,
                      [location]: {
                        ...tempMapping[location],
                        [UAN]: activityMapping[location][UAN],
                      },
                    }
                  }
                } else {
                  tempMapping = { ...tempMapping, ...activityMapping }
                }
              }
              fetchContext.authAxios
                .post(`/api_client_onboard/assign_activities`, {
                  activity_mapping: tempMapping,
                })
                .then((res) => {
                  dispatch({
                    type: 'UPDATE_USER_ASSIGNMENT',
                    payload: tempMapping,
                  })
                  if (lawarea) {
                    toast.success(
                      `Users successfully assigned to ${selectedActivities.length} activities of Law Area '${lawarea}'`,
                      { duration: 4000 },
                    )
                  } else {
                    toast.success(
                      `Users successfully assigned to ${selectedActivities.length} ${
                        selectedActivities.length === 1 ? 'activity' : 'activities'
                      }`,
                      { duration: 4000 },
                    )
                  }

                  handlesDialogClose()
                  setIsLoading(false)
                  if (Object.keys(oldMapping).length === 0 && !isUserExist) {
                    setUserOnboardPromptState(true)
                  }
                  if (showOnlyUnassigned) {
                    setSelectedActivities([])
                  }
                })
                .catch((error) => {
                  setIsLoading(false)
                  toast.error('There was an error!')
                })
            }}>
            {({
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              isValid,
              dirty,
              initialValues,
              setFieldValue,
            }) => (
              <Form>
                <div style={{ width: '400px' }}>
                  <Grid container>
                    <Grid item xs={10}>
                      <SelectInput
                        name="tracker_admin"
                        label="Tracker Owner"
                        fullWidth={true}
                        error={Boolean(touched.tracker_admin) && Boolean(errors.tracker_admin)}
                        items={ownerList}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        className={classes.addButton}
                        aria-label="add"
                        title="Add Tracker Owner"
                        onClick={() => {
                          handleAddUserDialogOpen('tracker_admin')
                        }}>
                        <AddCircleOutlineIcon fontSize="large" />
                      </IconButton>
                    </Grid>

                    <Grid item xs={10}>
                      <SelectInput
                        error={Boolean(touched.tracker_manager) && Boolean(errors.tracker_manager)}
                        name="tracker_manager"
                        label="Tracker Manager"
                        fullWidth={true}
                        items={managerList}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        className={classes.addButton}
                        aria-label="add"
                        title="Add Tracker Manager"
                        onClick={() => {
                          handleAddUserDialogOpen('tracker_manager')
                        }}>
                        <AddCircleOutlineIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>

                <DialogActions>
                  <LoadingButton
                    loading={isLoading}
                    type="submit"
                    color="primary"
                    disabled={!(dirty && isValid) || isLoading}
                    variant="contained"
                    size="large">
                    Submit
                  </LoadingButton>
                </DialogActions>
                <AddUser
                  openState={addUserDialogState.openState}
                  role={addUserDialogState.role}
                  handlesDialogClose={handleAddUserDialogClose}
                  setfieldvalue={setFieldValue}
                />
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <UserOnboardPrompt
        openState={userOnboardPromptState}
        handleOnobardUserClose={handleOnobardUserClose}
      />
    </>
  )
}

export default AssignUser
