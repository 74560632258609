import React, { createContext, useState } from 'react'

interface ProviderProps {
  children: React.ReactNode
}

export interface ContextProps {
  callOutGotIt: boolean
  callOutOpen: boolean
  closeAssignUserCallOut: () => void
  SetCallOutOpen: () => void
}

const AssignUserCallOutContext = createContext<ContextProps>({
  callOutGotIt: false,
  callOutOpen: false,
  closeAssignUserCallOut: () => {},
  SetCallOutOpen: () => {},
})
const { Provider } = AssignUserCallOutContext

const AssignUserCallOutProvider = ({ children }: ProviderProps) => {
  const [callOutGotIt, setCallOutGotIt] = useState<boolean>(false)
  const [callOutOpen, setCallOutOpen] = useState<boolean>(false)
  const closeAssignUserCallOut = () => {
    setCallOutOpen(false)
    setCallOutGotIt(true)
  }

  const SetCallOutOpen = () => {
    setCallOutOpen(true)
  }
  return (
    <Provider
      value={{
        callOutGotIt,
        callOutOpen,
        closeAssignUserCallOut: () => closeAssignUserCallOut(),
        SetCallOutOpen: () => SetCallOutOpen(),
      }}>
      {children}
    </Provider>
  )
}

export { AssignUserCallOutContext, AssignUserCallOutProvider }
