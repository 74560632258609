import { truncate } from 'fs'
import { activity, CompanyActivities } from '../Types/activity'
import { ActivityMapping } from '../Types/activityMapping'

export const removeItem = (array: string[], item: string) => {
  var index = array.indexOf(item)
  if (index !== -1) {
    array.splice(index, 1)
  }
  return array
}

export const isEmpty = (value: any) => {
  if (typeof value === undefined) {
    return true
  } else if (Array.isArray(value) || typeof value === 'string') {
    if (value.length === 0) {
      return true
    }
  } else if (typeof value === 'object') {
    if (Object.keys(value).length === 0) {
      return true
    }
  } else {
    if (!value) {
      return true
    }
  }

  return false
}

export const getIDForName = (valuList: { [key: string]: string }[], name: string) => {
  for (var i: number = 0; i < valuList.length; i++) {
    if (valuList[i].name.toLowerCase() === name.toLowerCase()) {
      return valuList[i]
    }
  }
  return {}
}

export const getUniqueLawareas = (activities: activity[]) => {
  let uniqueLawareas: string[] = []
  activities.forEach((activity) => {
    if (!uniqueLawareas.includes(activity['Law Area'])) {
      uniqueLawareas.push(activity['Law Area'])
    }
  })
  return uniqueLawareas
}

export const isAnyActivityAssigned = (activityMapping: ActivityMapping) => {
  if (!activityMapping || Object.keys(activityMapping).length === 0) {
    return false
  }
  for (var location of Object.keys(activityMapping)) {
    if (location in activityMapping) {
      if (Object.keys(activityMapping[location]).length > 0) {
        return true
      }
    }
  }
  return false
}
