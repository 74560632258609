import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  type: 'TOTAL' | 'ASSIGNED'
  stat: number
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '20px',
    minWidth: '105px',
  },
  text: {
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#192A3E',
    opacity: '0.5',
    fontSize: '10px',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  stat: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    width: 'fit-content',
    padding: '3px 15px',
    borderRadius: '24px',
  },
  TOTAL: {
    background: '#C8E6C9',
    color: '#388E3C',
  },
  ASSIGNED: {
    background: '#FFF59D',
    color: '#F9A825',
  },
}))

const StatusText: React.FC<Props> = ({ type, stat }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.text}>{type === 'TOTAL' ? 'Total' : 'Assigned'}</div>
      <div className={`${classes.stat} ${classes[type]}`}>{stat}</div>
    </div>
  )
}

export default StatusText
