import { Button, Container, CssBaseline, makeStyles, Slide } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import LoadingButton from '../LoadingButton'
import { FetchContext } from '../../Context/FetchContext'
import * as yup from 'yup'
import Skeleton from '../../Components/Skeleton'
import { SlideProps, Props, Question, initialVals, QuickFill, Options } from './types'
import { backHandler, submitHandler } from './handlers'
import JSONFormField from '../JSONFormField'
import { getActiveQuestion } from './helper'
import { isEmpty } from '../../util/common'
import { toast } from 'react-hot-toast'
import QuestionCard from '../QuestionCard'
import { ArrowBackSharp, ArrowForwardSharp } from '@material-ui/icons'
import { StepWizardContext } from '../OnboardingSteps'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../Context/AuthContext'
import { SelectedLocationContext } from '../../Context/SelectedLocations'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import CenterContent from '../CenterContent'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  submit: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    margin: theme.spacing(3, 0, 3, 1),
    borderRadius: 5,
    width: '48.5%',
    height: '50px',
    color: '#242731',
    backgroundColor: 'white',
  },
  back: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    margin: theme.spacing(3, 1, 3, 0),
    width: '48.5%',
    height: '50px',
    borderRadius: 5,
    backgroundColor: 'white',
    color: '#242731',
  },
  list: {
    width: '100%',
    maxWidth: 325,
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(16, 0, 0, 0),
    marginBottom: '30px',
    border: '1px solid #EEEEEE',
  },
  flexContainer: {
    display: 'flex',
    width: 'fill-available',
  },
  left: {
    flex: 1,
  },
  center: {
    flext: 1,
  },
  right: {
    flex: 1,
  },
}))

const Questionnaire: React.FC<Props> = ({ history }) => {
  const { globalState, dispatch } = useContext(StepWizardContext)
  const authContext = useContext(AuthContext)
  const LocationContext = useContext(SelectedLocationContext)
  const [slideIn, setSlideIn] = useState<boolean>(true)
  const [slideDirection, setSlideDirection] = useState<SlideProps>('left')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [initialData, setInitialData] = useState<initialVals>({})
  const [questionListLoaded, setQuestionListLoaded] = useState<boolean>(false)
  const [validationSchema, setValidationSchema] = useState<
    | yup.StringSchema<string | undefined, Record<string, any>, string | undefined>
    | yup.NumberSchema<number | undefined, Record<string, any>, number | undefined>
    | yup.DateSchema<Date | undefined, Record<string, any>, Date | undefined>
    | yup.ArraySchema<any, Record<string, any>, any[] | undefined, any[] | undefined>
    | undefined
  >(undefined)

  const fetchContext = useContext(FetchContext)

  const activeQuestion = getActiveQuestion(globalState.questionList)
  const classes = useStyles()

  useEffect(() => {
    const activeQuestion = getActiveQuestion(globalState.questionList)
    if (!globalState.dataSynced) {
      fetchContext.authAxios
        .get(`/api_client_onboard/get_onboard_info`)
        .then((res) => {
          dispatch({ type: 'SYNC_DATA', payload: res.data[authContext.authState.userInfo.email] })
        })
        .catch((error) => {
          console.log(error)
        })
    }
    if (globalState.questionList.length === 0 && !questionListLoaded) {
      const values = { workflow: globalState.workflow, version: 'Free' }

      fetchContext.authAxios
        .post(`/api_client_onboard/get_next_question`, values)
        .then((res) => {
          dispatch({ type: 'SET_QUESTIONS', payload: res.data.result })
          setQuestionListLoaded(true)
        })
        .catch((error: any) => {
          setQuestionListLoaded(true)
          toast.error('Something went wrong!')
        })
    } else {
      setQuestionListLoaded(true)
    }
    var a
    if (['text', 'textarea', 'radio', 'select'].includes(activeQuestion.type)) {
      a = yup.string()
      if (activeQuestion.mandatory) {
        a = a.required('Field can not be empty.')
      }
    } else if (activeQuestion.type === 'number') {
      a = yup.number()
      if (activeQuestion.mandatory) {
        a = a.required('Field can not be empty.')
      }
    } else if (activeQuestion.type === 'date') {
      a = yup.date()
      if (activeQuestion.mandatory) {
        a = a.required('Field can not be empty.')
      }
    } else if (['multiselect', 'checklist'].includes(activeQuestion.type)) {
      a = yup.array()
      if (activeQuestion.mandatory) {
        a = a.min(1, 'Select at least one item').of(
          yup
            .object()
            .shape({
              label: yup.string(),
              value: yup.string(),
            })
            .nullable(),
        )
      }
    }
    setValidationSchema(a)

    setInitialData({ [activeQuestion.name]: activeQuestion.value })
  }, [globalState.questionList])

  const quickFill: QuickFill | null =
    activeQuestion.quick_fill === undefined ||
    activeQuestion.quick_fill === null ||
    Object.keys(activeQuestion.quick_fill).length === 0 ||
    Object.keys(activeQuestion.quick_fill.options).length === 0
      ? null
      : activeQuestion.quick_fill
  return (
    <Slide in={slideIn} direction={slideDirection} mountOnEnter>
      <div style={{ width: '100%' }}>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={initialData}
          validationSchema={yup.object().shape({
            [activeQuestion.name]: validationSchema ? validationSchema : yup.string(),
          })}
          onSubmit={(values, setFieldError) => {
            submitHandler(
              fetchContext,
              globalState,
              dispatch,
              history,
              activeQuestion,
              values,
              setSlideIn,
              setSlideDirection,
              setIsLoading,
              setFieldError,
              LocationContext,
            )
          }}>
          {({
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
            dirty,
            setErrors,
            setFieldValue,
            setFieldError,
          }) => (
            <>
              <div className={classes.flexContainer}>
                <div className={classes.left}></div>
                <div className={classes.center}>
                  <Container component="main" maxWidth="sm" style={{ minWidth: '550px' }}>
                    <CssBaseline />
                    <Helmet>
                      <title>Questionnaire</title>
                    </Helmet>
                    {!questionListLoaded ? (
                      <Skeleton skeletonCountArr={[1, 2, 3, 4]} />
                    ) : globalState.questionList.length === 0 ? (
                      <QuestionCard question={'Something went wrong!'}>
                        <>No Questions to display.</>
                      </QuestionCard>
                    ) : (
                      <Form className={classes.form} noValidate>
                        <QuestionCard
                          question={activeQuestion.question}
                          questionTitle={activeQuestion.title}
                          questionDescription={activeQuestion.description}
                          answerHelpText={activeQuestion.help_text}
                          readMoreLink={activeQuestion.readmore}>
                          <JSONFormField
                            question={activeQuestion}
                            error={
                              touched[activeQuestion.name] && Boolean(errors[activeQuestion.name])
                            }
                          />
                        </QuestionCard>
                        <div>
                          <Button
                            className={classes.back}
                            disabled={
                              globalState.questionList.findIndex(
                                (question) => question.id === activeQuestion.id,
                              ) === 0 || isLoading
                            }
                            variant="outlined"
                            onClick={() =>
                              backHandler(activeQuestion, setSlideIn, setSlideDirection, dispatch)
                            }>
                            <ArrowBackSharp fontSize="small" />
                            Back
                          </Button>
                          <LoadingButton
                            loading={isLoading}
                            type="submit"
                            variant="outlined"
                            size="large"
                            className={classes.submit}
                            disabled={
                              dirty ? (isValid ? isLoading : true) : isValid ? isLoading : true
                            }>
                            {activeQuestion.mandatory
                              ? 'Next'
                              : isEmpty(values[activeQuestion.name])
                              ? 'Skip'
                              : 'Next'}
                            <ArrowForwardSharp fontSize="small" />
                          </LoadingButton>
                        </div>
                      </Form>
                    )}
                  </Container>
                </div>
                <div className={classes.right}>
                  {quickFill && (
                    <div>
                      <List
                        className={classes.list}
                        component="nav"
                        subheader={
                          <ListSubheader component="div" id="nested-list-subheader">
                            <CenterContent>{quickFill.title}</CenterContent>
                          </ListSubheader>
                        }>
                        <Divider />
                        <div style={{ maxHeight: '240px', overflow: 'auto' }}>
                          {Object.keys(quickFill.options).map((option) => {
                            return (
                              <ListItem
                                button
                                onClick={(event) => {
                                  var fillValue: string | string[] = quickFill.options[option]
                                  if (['multiselect', 'checklist'].includes(activeQuestion.type)) {
                                    // Keep only those options which are present in options list
                                    fillValue = activeQuestion.options
                                      .filter((o) => {
                                        return fillValue.includes(o.value)
                                      })
                                      .map((f) => f.value)
                                  } else if (activeQuestion.type === 'select') {
                                    const tempOptions: Options[] = activeQuestion.options.filter(
                                      (o) => {
                                        return fillValue === o.value
                                      },
                                    )
                                    if (tempOptions.length === 0) {
                                      fillValue = ''
                                    }
                                  }
                                  setFieldValue(activeQuestion.name, fillValue)
                                }}>
                                <ListItemText primary={option} />
                              </ListItem>
                            )
                          })}
                        </div>
                      </List>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </Slide>
  )
}

export default withRouter(Questionnaire)
