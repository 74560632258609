import React, { ReactNode } from 'react'

import { Field, FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { getFormattedDate } from '../../../util/dateUtil'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}))

interface DateInputProps {
  name: string
  label: string
  placeholder?: string
  required?: boolean
  error: boolean
}

interface DatePickerMuiProps extends FieldProps {
  children: ReactNode
  label: string
  errorText: string
  error: boolean
  placeholder: string
}

const DatePickerField: React.FC<DatePickerMuiProps> = ({
  placeholder,
  field,
  form,
  error,
  ...other
}) => {
  const currentError = form.errors[field.name] || ''
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        name={field.name}
        placeholder={placeholder}
        autoOk={true}
        inputVariant="standard"
        variant="inline"
        fullWidth
        openTo="year"
        views={['year', 'month', 'date']}
        value={field.value ? field.value : null}
        format="yyyy-MM-dd"
        onChange={(date) => {
          form.setFieldValue(field.name, getFormattedDate(date), true)
        }}
        onClose={() => {
          form.setFieldTouched(field.name, true)
        }}
        {...other}
        error={error}
        helperText={error ? currentError : ''}
      />
    </MuiPickersUtilsProvider>
  )
}
const DateInput: React.FC<DateInputProps> = ({
  name,
  label,
  error,
  required = true,
  placeholder = '',
}) => {
  return (
    <Field
      name={name}
      component={DatePickerField}
      label={label}
      required={required}
      error={error}
      placeholder={placeholder}
    />
  )
}

export default DateInput
