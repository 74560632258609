import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'

interface Props {
  questionTitle?: string
  questionDescription?: string
  question: string
  answerHelpText?: string
  readMoreLink?: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '550px',
    border: '1px solid #EEEEEE',
    boxSizing: 'border-box',
    borderRadius: '6px',
  },
  header: {},
  headerText: {
    left: '34.17%',
    right: '34.17%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    color: '#242731',
    margin: theme.spacing(1, 0, 2, 0),
  },
  questionDescription: {
    margin: theme.spacing(2, 0, 2, 0),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#575F6E',
  },
  childrenContainer: {
    fontFamily: 'Roboto',
    margin: theme.spacing(2, 0, 0, 0),
  },
  questionContainer: {
    background: '#FFFFFF',
    padding: '40px',
  },
  question: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#242731',
  },
  answerHelpText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '21px',
    display: 'flex',
    alignItems: 'center',
    color: '#575F6E',
    margin: theme.spacing(2, 0, 2, 0),
  },
}))

const QuestionCard: React.FC<Props> = ({
  children,
  questionTitle = '',
  question,
  questionDescription = '',
  answerHelpText = '',
  readMoreLink = '',
}) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.header}>
        {questionTitle && (
          <>
            <div className={classes.headerText}>{questionTitle}</div>
            {questionDescription && (
              <div className={classes.questionDescription}>
                {<span dangerouslySetInnerHTML={{ __html: questionDescription }}></span>}
                {readMoreLink && (
                  <Link target="_blank" href={readMoreLink}>
                    {' '}
                    Read More.
                  </Link>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className={classes.container}>
        <div className={classes.questionContainer}>
          <h3 className={classes.question}>{question}</h3>
          {answerHelpText && (
            <div className={classes.answerHelpText}>
              {<span dangerouslySetInnerHTML={{ __html: answerHelpText }}></span>}
            </div>
          )}
          <div className={classes.childrenContainer}>{children}</div>
        </div>
      </div>
    </>
  )
}

export default QuestionCard
