import React, { ReactNode } from 'react'
import { Field, ErrorMessage, FieldInputProps } from 'formik'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
)

export interface SelectItem {
  value: string
  label: string
}

interface SelectInputProps {
  name: string
  items: SelectItem[]
  label: string
  error?: boolean
  required?: boolean
  fullWidth?: boolean
  variant?: string
  placeholder?: string
}

interface SelectMuiProps extends FieldInputProps<string> {
  children: ReactNode
  label: string
  error: boolean
  required: boolean
  variant: 'outlined' | 'filled' | 'standard' | undefined
  errorText: string
  items: SelectItem[]
}

const SelectMui: React.FC<SelectMuiProps> = ({
  children,
  label,
  value,
  name,
  error,
  required,
  variant,
  errorText,
  items,
  onChange,
  onBlur,
}) => {
  const classes = useStyles()
  return (
    <FormControl
      error={error}
      fullWidth
      variant={variant}
      required={required}
      className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        error={error}
        name={name}
        multiple
        onChange={onChange}
        onBlur={onBlur}
        value={value ? value : []}
        label={label}
        renderValue={(selected) => {
          const selectedItems: SelectItem[] = items.filter((item) =>
            (selected as string[]).includes(item.value),
          )
          return (
            <div className={classes.chips}>
              {selectedItems.map((item) => (
                <Chip key={item.value} label={item.label} className={classes.chip} />
              ))}
            </div>
          )
        }}>
        {children}
      </Select>
      <FormHelperText error={true}>{error ? errorText : ''}</FormHelperText>
    </FormControl>
  )
}

const MultiDropdown: React.FC<SelectInputProps> = ({
  name,
  items,
  label,
  error = false,
  required = false,
  fullWidth = true,
  variant = 'outlined',
  placeholder = '',
}) => {
  // If placeholder exist then insert placeholder item at first position
  if (placeholder) {
    items = [{ value: '', label: placeholder }].concat(items)
  }
  return (
    <Field
      as={SelectMui}
      format
      name={name}
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      error={error}
      required={required}
      items={items}
      errorText={<ErrorMessage name={name} />}>
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value} disabled={item.value ? false : true}>
          {item.label}
        </MenuItem>
      ))}
    </Field>
  )
}

export default MultiDropdown
