import React, { createContext, Dispatch, useEffect, useReducer } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Redirect, RouteComponentProps, useParams, withRouter } from 'react-router-dom'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import VerifyOTP from '../../Pages/VerifyOTP'
import AddCompany from '../../Pages/AddCompany'
import AddCompliances from '../../Pages/AddCompliances'
import ReviewOnboarding from '../../Pages/ReviewOnboarding'
import { StepWizardActionTypes, StepWizardInitialStateType } from '../../Types/stepWizardTypes'
import stepWizardReducer from '../../Reducers/stepWizardReducer'
import { Route, Switch } from 'react-router-dom'
import Success from '../../Pages/Success'
import HelperModal from '../HelperModal'
import { LocationProvider } from '../../Context/SelectedLocations'
import ProtectedRoute from '../ProtectedRoute'
import Questionnaire from '../Questionnaire'

interface Props extends RouteComponentProps {}

export const companyDefault = {
  name: '',
  cin: '',
  date_of_inc: null,
  puc_currency: 'SGD',
  paidup_capital: '',
  sector: [],
  industry: [],
  entity_type: '',
  sector_activity: '',
}
export const initialState = {
  workflow: '',
  OTPVerified: false,
  dataSynced: false,
  stepCount: 0,
  loginStep: null,
  addCompanyStep: null,
  configureCompliancesStep: null,
  company: companyDefault,
  questionnaire: {},
  locations: [],
  users: [],
  masterActivityList: [],
  companyActivities: {},
  activityMapping: {},
  defaultValues: { set: false, country: '', state: '', city: '' },
  isHelperModalOpen: false,
  helperTextID: '',
  activeStep: 0,
  helperTextData: [],
  notification: {},
  questionList: [],
}

export const StepWizardContext = createContext<{
  globalState: StepWizardInitialStateType
  dispatch: Dispatch<StepWizardActionTypes>
}>({
  globalState: initialState,
  dispatch: () => null,
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    stepper: {
      backgroundColor: '#fafafa',
      width: 'fit-content',
    },
  }),
)

function getSteps() {
  return ['Login', 'Add Company', 'Configure Compliances', 'Review and Save']
}

const OnBoardingSteps: React.FC<Props> = ({ match: { path, url }, history: { push } }) => {
  const { workflow, page } = useParams<{ workflow: string; page: string }>()
  initialState['workflow'] = workflow
  const [globalState, dispatch] = useReducer(stepWizardReducer, initialState)

  const classes = useStyles()
  const steps = getSteps()

  let stepNumber: number = 0

  useEffect(() => {
    if (page === 'login') {
      stepNumber = 0
    } else if (page === 'add-company') {
      stepNumber = 1
    } else if (page === 'add-compliances') {
      stepNumber = 2
    } else if (page === 'review-onboarding') {
      stepNumber = 3
    } else if (page === 'success') {
      stepNumber = 4
    } else {
      stepNumber = -1
    }
    dispatch({ type: 'SET_STEP', payload: stepNumber })
  }, [page])

  return (
    <StepWizardContext.Provider value={{ globalState, dispatch }}>
      <LocationProvider>
        <div className={classes.container}>
          <Stepper className={classes.stepper} activeStep={globalState.stepCount} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label.toLocaleUpperCase()}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <HelperModal />
          <Switch>
            <Route path={`/onboarding/${workflow}/login`} component={VerifyOTP} />
            <ProtectedRoute
              path={`/onboarding/${workflow}/add-company`}
              component={globalState.workflow.toLowerCase() === 'mas' ? AddCompany : Questionnaire}
              workflow={globalState.workflow}
            />
            <ProtectedRoute
              path={`/onboarding/${workflow}/add-compliances`}
              component={AddCompliances}
              workflow={globalState.workflow}
            />
            <ProtectedRoute
              path={`/onboarding/${workflow}/review-onboarding`}
              component={ReviewOnboarding}
              workflow={globalState.workflow}
            />
            <ProtectedRoute
              path={`/onboarding/${workflow}/success`}
              component={Success}
              workflow={globalState.workflow}
            />
            {/* Default Redirect if path not matched */}
            <Redirect to={`/onboarding/${workflow}/login`} />
          </Switch>
        </div>
      </LocationProvider>
    </StepWizardContext.Provider>
  )
}

export default withRouter(OnBoardingSteps)
