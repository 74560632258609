import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form, Formik, FormikHelpers } from 'formik'
import TextInput from '../../Components/FormikMuiFields/TextInput'
import IconButton from '@material-ui/core/IconButton/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import * as yup from 'yup'
import Typography from '@material-ui/core/Typography'
import MultiSelect from '../../Components/FormikMuiFields/MultiSelect'
import { useEffect, useState } from 'react'
import { useContext } from 'react'
import { StepWizardContext } from '../../Components/OnboardingSteps'
import { activity } from '../../Types/activity'
import apiClient from '../../util/publicFetch'
import { SelectedLocationContext } from '../../Context/SelectedLocations'
import { FetchContext } from '../../Context/FetchContext'
import { toast } from 'react-hot-toast'
import LoadingButton from '../LoadingButton'
import { StepWizardActionTypes, StepWizardInitialStateType } from '../../Types/stepWizardTypes'
import { Dispatch } from 'react'
import { removeItem } from '../../util/common'
import ToolTip from '../ToolTip'
import CenterContent from '../CenterContent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    formContainer: {
      paddingLeft: '25px',
    },
    officeTypeHelpText: {
      margin: 'auto',
    },
  }),
)

export interface OfficeType {
  id: number
  name: string
}

interface LocationFormikProps {
  name: string
  address: string
  office_type: number[]
  country: string
  state: string
  city: string
}
interface LocationFormDialogProps extends LocationFormikProps {
  openState: boolean

  handlesDialogClose: () => void
}

const updateLocation = (
  dispatch: Dispatch<StepWizardActionTypes>,
  initialVals: LocationFormikProps,
  values: LocationFormikProps,
  globalState: StepWizardInitialStateType,
  officeTypeList: OfficeType[],
) => {
  dispatch({
    type: 'MODIFY_LOCATION',
    payload: {
      oldLocationName: initialVals.name,
      newLocation: {
        name: values.name,
        address: values.address,
        office_type: officeTypeList.filter((ot) => values.office_type.includes(ot.id)),
        country: values.country,
        state: values.state,
        city: values.city,
      },
    },
  })
  let tempActivities: activity[] = globalState.masterActivityList
  tempActivities = tempActivities.map((actv) => {
    return { ...actv, location: values.name, id: actv.UAN + '_' + values.name }
  })

  dispatch({
    type: 'SET_COMPANY_ACTIVITIES',
    payload: { location: values.name, activities: tempActivities },
  })
}
const LocationFormDialog: React.FC<LocationFormDialogProps> = ({
  openState,
  name,
  address,
  office_type,
  country,
  state,
  city,
  handlesDialogClose,
}) => {
  const fetchContext = useContext(FetchContext)
  const LocationContext = useContext(SelectedLocationContext)
  const { globalState, dispatch } = useContext(StepWizardContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  let initialVals: LocationFormikProps = {
    name: name,
    address: address,
    office_type: office_type,
    country: country,
    state: state,
    city: city,
  }
  const classes = useStyles()
  const [officeTypeList, setOfficeTypeList] = useState<OfficeType[]>([])

  let validationSchema = yup.object().shape({
    name: yup
      .string()
      .required()
      .lowercase()
      .notOneOf(
        globalState.locations.map((l) => {
          if (l.name !== name) {
            return l.name.toLowerCase()
          }
        }),
        'Location with this name already exist',
      ),
    address: yup.string(),
    office_type: yup
      .array()
      .min(1, 'Select at least one Office Type')
      .of(
        yup
          .object()
          .shape({
            id: yup.string(),
            name: yup.string(),
          })
          .nullable(),
      ),
  })
  useEffect(() => {
    if (officeTypeList.length === 0) {
      apiClient
        .get(`/api_client_onboard/get_officetypes`)
        .then((res) => {
          const otDisplayList = [
            'Corporate office',
            'Finance',
            'Banks',
            'Merchant Banks',
            'Law Firms',
          ]
          const oTypes: OfficeType[] = res.data.result.filter((ot: OfficeType) =>
            otDisplayList.includes(ot.name),
          )
          setOfficeTypeList(oTypes)
        })
        .catch((error) => {
          //
        })
    }
  }, [])
  return (
    <Dialog open={openState} onClose={handlesDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">Add Location</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handlesDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={initialVals}
          validationSchema={validationSchema}
          onSubmit={(
            values: LocationFormikProps,
            { setErrors }: FormikHelpers<LocationFormikProps>,
          ) => {
            setIsLoading(true)
            if (initialVals.name && initialVals.name !== values.name) {
              fetchContext.authAxios
                .put(`/api_client_onboard/location`, {
                  old_name: initialVals.name,
                  loc_info: {
                    ...values,
                    country: globalState.defaultValues.country,
                    state: globalState.defaultValues.state,
                    city: globalState.defaultValues.city,
                  },
                })
                .then((res) => {
                  updateLocation(dispatch, initialVals, values, globalState, officeTypeList)
                  let oldSelectedLocations: string[] = LocationContext.selectedLocations
                  oldSelectedLocations = removeItem(oldSelectedLocations, initialVals.name)
                  LocationContext.setSelectedLocations([...oldSelectedLocations, values.name])
                  setIsLoading(false)
                  handlesDialogClose()
                })
                .catch((error) => {
                  if (error.response.status === 400 && values.name in error.response.data.error) {
                    setErrors(error.response.data.error[values.name])
                  } else {
                    toast.error('Refresh and try again!')
                  }
                  setIsLoading(false)
                  handlesDialogClose()
                })
            } else {
              fetchContext.authAxios
                .post(`/api_client_onboard/location`, {
                  locations: {
                    [values.name]: {
                      ...values,
                      country: globalState.defaultValues.country,
                      state: globalState.defaultValues.state,
                      city: globalState.defaultValues.city,
                    },
                  },
                })
                .then((res) => {
                  updateLocation(dispatch, initialVals, values, globalState, officeTypeList)
                  LocationContext.setSelectedLocations([
                    ...LocationContext.selectedLocations,
                    values.name,
                  ])
                  setIsLoading(false)
                  handlesDialogClose()
                })
                .catch((error) => {
                  if (error.response.status === 400 && values.name in error.response.data.error) {
                    setErrors(error.response.data.error[values.name])
                  } else {
                    handlesDialogClose()
                    toast.error('Try again!')
                  }
                  setIsLoading(false)
                })
            }
          }}>
          {({
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
            dirty,
            initialValues,
          }) => (
            <Form>
              <Grid container spacing={2} className={classes.formContainer}>
                <Grid item xs={11}>
                  <TextInput
                    name="name"
                    required
                    autoFocus
                    placeholder="Your Personalized Name: Singapore HQ, Chinatown, etc"
                    margin="normal"
                    label="Name"
                    fullWidth
                    autoComplete="off"
                    error={touched.name && Boolean(errors.name)}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                  <TextInput
                    name="address"
                    margin="normal"
                    label="Address"
                    fullWidth
                    autoComplete="off"
                    multiline={true}
                    rows={4}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                  <MultiSelect name="office_type" label="Office Type" items={officeTypeList} />
                </Grid>
                <Grid item xs={1} className={classes.officeTypeHelpText}>
                  <ToolTip id="officeType" activeStepIndex={1} />
                </Grid>
              </Grid>
              <DialogActions>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  color="primary"
                  disabled={dirty ? (isValid ? isLoading : true) : name ? isLoading : true}
                  variant="contained"
                  size="large">
                  {name ? 'Update' : 'Add'}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default LocationFormDialog
