import React from 'react'
import FormCard from '../FormCard/FormCard'
import CardItem, { data } from './CardItem'
import reviewSectorIcon from '../../assets/review_sector.svg'
import { styled } from '@material-ui/core/styles'
import { useContext } from 'react'
import { StepWizardContext } from '../OnboardingSteps'
import { useState } from 'react'
import { useEffect } from 'react'
import publicFetch from '../../util/publicFetch'
import { Sectors } from '../../Pages/AddCompany'

interface Props {
  data: data[]
}

const Container = styled('div')({})

const BasicInformation: React.FC<Props> = ({ data }) => {
  const { globalState } = useContext(StepWizardContext)

  const [companyInfo, setCompanyInfo] = useState<data[]>(data)

  useEffect(() => {
    if (globalState.workflow.toLowerCase() === 'mas') {
      publicFetch
        .get(`/api_client_onboard/get_sectors`)
        .then((res) => {
          const sectorList: Sectors[] = res.data.result.filter((sector: Sectors) => {
            return globalState.company.sector.includes(sector.id)
          })
          setCompanyInfo((prevData) => {
            data = [...prevData]
            data[5] = {
              id: 6,
              img: reviewSectorIcon,
              label: 'Sector',
              details: sectorList,
            }
            return data
          })
        })
        .catch((error) => {})
    }
  }, [])

  return (
    <Container>
      <FormCard isReviewCard header="Company Information">
        <CardItem data={companyInfo} />
      </FormCard>
    </Container>
  )
}

export default BasicInformation
