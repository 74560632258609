import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Button, Grid } from '@material-ui/core'
import BasicInformation from '../../Components/review/BasicInformation'
import LocationDetails from '../../Components/review/LocationDetails'
import UserDetails from '../../Components/review/UserDetails'
import NotificationDetails from '../../Components/review/NotificationDetails'
import { makeStyles } from '@material-ui/core/styles'
import { AuthContext } from '../../Context/AuthContext'
import { FetchContext } from '../../Context/FetchContext'
import { StepWizardContext } from '../../Components/OnboardingSteps'
import { useContext } from 'react'
import { onBoardingStepTypes } from '../../Types/onBoardingStepTypes'
import { RouteComponentProps } from 'react-router-dom'
import Skeleton from '../../Components/Skeleton'
import reviewNameIcon from '../../assets/review_name.svg'
import reviewPaidUpCapitalIcon from '../../assets/review_paid_up_capital.svg'
import reviewDateOfIncIcon from '../../assets/review_data_of_inc.svg'
import reviewSectorIcon from '../../assets/review_sector.svg'
import reviewIndustryIcon from '../../assets/review_industry.svg'
import reviewListIcon from '../../assets/review_list.svg'
import { getFormattedDate } from '../../util/dateUtil'
import { CompanyActivities } from '../../Types/activity'
import { data } from '../../Components/review/CardItem'
import helperText from '../../data/helperText'
import { user } from '../../Types/user'
import { activity } from '../../Types/activity'
import { questionnaire } from '../../Types/stepWizardTypes'
import { isAnyActivityAssigned } from '../../util/common'

interface Props extends RouteComponentProps<{ step: onBoardingStepTypes }> {}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    padding: '0 100px',
  },
}))

const locationActivityCount = (locObject: CompanyActivities) => {
  let count: number = 0
  for (let locName in locObject) {
    count = count + locObject[locName].length
  }
  return count
}

const ReviewOnboarding: React.FC<Props> = ({ history: { push } }) => {
  const authContext = useContext(AuthContext)
  const fetchContext = useContext(FetchContext)
  const { globalState, dispatch } = useContext(StepWizardContext)

  let totalCount: number = locationActivityCount(globalState.companyActivities)
  let data: data[] = []
  if (globalState.workflow.toLowerCase() === 'mas') {
    data = [
      {
        id: 1,
        img: reviewNameIcon,
        label: 'name',
        desc: globalState.company.name,
      },
      {
        id: 2,
        img: reviewNameIcon,
        label: 'UEN',
        desc: globalState.company.cin,
      },
      {
        id: 3,
        img: reviewDateOfIncIcon,
        label: 'date of incorporation',
        desc: Boolean(globalState.company.date_of_inc)
          ? getFormattedDate(globalState.company.date_of_inc)
          : '',
      },
      {
        id: 4,
        img: reviewPaidUpCapitalIcon,
        label: 'paid up capital',
        desc:
          globalState.company.paidup_capital +
          ' ' +
          (globalState.company.paidup_capital ? globalState.company.puc_currency : ''),
      },
      {
        id: 5,
        img: reviewIndustryIcon,
        label: 'Entity Type',
        details: [
          {
            id: 1,
            name: globalState.company.entity_type,
          },
        ],
      },
      {
        id: 6,
        img: reviewSectorIcon,
        label: 'Sector',
        details: [],
      },
      {
        id: 7,
        img: reviewIndustryIcon,
        label: 'Sector Activity',
        details: [
          {
            id: 1,
            name: globalState.company.sector_activity,
          },
        ],
      },
      {
        id: 8,
        img: reviewListIcon,
        label: 'Total Activities',
        details: [
          {
            id: 1,
            name: totalCount.toString(),
          },
        ],
      },
    ]
  } else {
    var questionnaire: questionnaire = {}
    if (globalState.questionList.length > 0) {
      globalState.questionList.forEach((q) => {
        questionnaire[q.name] = q.value
      })
    } else {
      questionnaire = { ...globalState.questionnaire }
    }
    var count_id: number = 0
    data = Object.keys(questionnaire).map((question) => {
      count_id = count_id + 1
      if (['string', 'number'].includes(typeof questionnaire[question])) {
        return {
          id: count_id,
          img: reviewIndustryIcon,
          label: question,
          desc: questionnaire[question] ? (questionnaire[question] as string) : '',
        }
      } else if (typeof questionnaire[question] === 'object') {
        return {
          id: count_id,
          img: reviewIndustryIcon,
          label: question,
          details: (questionnaire[question] as string[]).map((q) => {
            return { id: 1, name: q }
          }),
        }
      } else {
        return {
          id: count_id,
          img: reviewListIcon,
          label: '',
          desc: '',
        }
      }
    })
    if (globalState.questionList.length === 0) {
      data.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    }
    data.push({
      id: count_id + 1,
      img: reviewListIcon,
      label: 'Total Activities',
      details: [
        {
          id: 1,
          name: totalCount.toString(),
        },
      ],
    })
  }
  const classes = useStyles()
  useEffect(() => {
    if (!globalState.dataSynced) {
      fetchContext.authAxios
        .get(`/api_client_onboard/get_onboard_info`)
        .then((res) => {
          dispatch({ type: 'SYNC_DATA', payload: res.data[authContext.authState.userInfo.email] })

          if ('cmp_info' in res.data[authContext.authState.userInfo.email]) {
            if (
              (globalState.workflow.toLowerCase() === 'mas' &&
                !('name' in res.data[authContext.authState.userInfo.email].cmp_info)) ||
              (globalState.workflow.toLowerCase() !== 'mas' &&
                Object.keys(res.data[authContext.authState.userInfo.email].cmp_info).length === 0)
            ) {
              push(`/onboarding/${globalState.workflow}/add-company`)
              return
            }
          } else {
            push(`/onboarding/${globalState.workflow}/add-company`)
            return
          }

          let resKeys: string[] = Object.keys(res.data[authContext.authState.userInfo.email])
          if (!['loc_info', 'user_info', 'activity_mapping'].every((v) => resKeys.includes(v))) {
            push(`/onboarding/${globalState.workflow}/add-compliances`)
            return
          }
          if (Object.keys(res.data[authContext.authState.userInfo.email].loc_info).length === 0) {
            push(`/onboarding/${globalState.workflow}/add-compliances`)
            return
          }

          let userList: user[] = Object.values(
            res.data[authContext.authState.userInfo.email].user_info,
          )
          let seniorManagers: user[] = userList.filter((user: user) => {
            return user['role'] === 'senior_manager'
          })

          if (seniorManagers.length === 0) {
            push(`/onboarding/${globalState.workflow}/add-compliances`)
            return
          }
          if (
            !isAnyActivityAssigned(res.data[authContext.authState.userInfo.email].activity_mapping)
          ) {
            push(`/onboarding/${globalState.workflow}/add-compliances`)
            return
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    const data = helperText.filter((el) => el)
    // @ts-ignore
    dispatch({ type: 'SET_HELPER_MODAL_DATA', payload: data[3]['review-onboarding'] })
  }, [authContext, fetchContext])

  const submitHandler = () => {
    dispatch({
      type: 'NEXT_STEP',
    })
    push(`/onboarding/${globalState.workflow}/success`)
  }

  return (
    <div>
      <Helmet>
        <title>Onboarding: Review</title>
      </Helmet>
      <Grid justify="center" container spacing={2} className={classes.container}>
        {globalState.dataSynced ? (
          <>
            <Grid item xs={3}>
              <BasicInformation data={data} />
            </Grid>
            <Grid item xs={3}>
              <LocationDetails />
            </Grid>
            <Grid item xs={3}>
              <UserDetails />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3}>
              <Skeleton skeletonCountArr={[1, 2, 3]} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton skeletonCountArr={[1, 2, 3]} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton skeletonCountArr={[1, 2, 3]} />
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <NotificationDetails />
        </Grid>
      </Grid>
      <Grid justify="center" container spacing={2} className={classes.container}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <div style={{ textAlign: 'center' }}>
            <Button
              style={{ color: '#1D9DB7', margin: '10px' }}
              onClick={() => {
                push(`/onboarding/${globalState.workflow}/add-compliances`)
              }}>
              Back
            </Button>
          </div>
          <Button
            disabled={!globalState.dataSynced}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={submitHandler}>
            Complete Onboarding
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </div>
  )
}

export default ReviewOnboarding
