import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PeopleOutline from '@material-ui/icons/PeopleAlt'
import { Tooltip } from '@material-ui/core'
import CallOut from '../CallOut'
import { StepWizardContext } from '../OnboardingSteps'
import { AssignUserCallOutContext } from '../../Context/AssignUserCallOut'
import ToolTip from '../ToolTip'
import CenterContent from '../CenterContent'

const useStyles = makeStyles({
  root: {
    maxWidth: '185px',
    minWidth: '185px',
  },
  cardAction: {
    justifyContent: 'space-between',
  },
  allAssigned: {
    color: '#43a047a8',
  },
  partiallyAssigned: {
    color: '#f9a825ab',
  },
  notAssigned: {
    color: '#80808096',
  },
})

interface Props {
  tileIndex: number
  lawarea: string
  activityIDs: string[]
  assignedActivityIDs: string[]
  handleAssignUserDialogOpen: (lawarea?: string) => void
  setSelectedActivities: (value: React.SetStateAction<React.ReactText[]>) => void
}

const LawareaTile: React.FC<Props> = ({
  tileIndex,
  lawarea,
  activityIDs,
  assignedActivityIDs,
  handleAssignUserDialogOpen,
  setSelectedActivities,
}) => {
  const AssignUserContext = useContext(AssignUserCallOutContext)

  const {
    globalState: { locations, activityMapping },
  } = useContext(StepWizardContext)

  const classes = useStyles()
  let iconClass = classes.notAssigned
  let iconTitle = 'Not Assigned Any'
  if (assignedActivityIDs.length === 0) {
  } else if (assignedActivityIDs.length < activityIDs.length) {
    iconClass = classes.partiallyAssigned
    iconTitle = 'Partially Assigned'
  } else if (assignedActivityIDs.length === activityIDs.length) {
    iconClass = classes.allAssigned
    iconTitle = 'All Assigned'
  }

  useEffect(() => {
    if (
      !AssignUserContext.callOutGotIt &&
      locations.length > 0 &&
      Object.keys(activityMapping).length === 0
    ) {
      AssignUserContext.SetCallOutOpen()
    }
  }, [locations, activityMapping, AssignUserContext])
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {lawarea}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {activityIDs.length} Activities
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        {tileIndex === 0 ? (
          <>
            <CallOut
              content={
                <>
                  Click here to Assign Users for the Law Area.
                  <br /> Not mandatory to assign users for all <br /> Law Areas.
                </>
              }
              open={AssignUserContext.callOutOpen}
              placement="right"
              onClose={() => AssignUserContext.closeAssignUserCallOut()}>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  AssignUserContext.closeAssignUserCallOut()
                  setSelectedActivities(activityIDs)
                  handleAssignUserDialogOpen(lawarea)
                }}>
                Assign Users
              </Button>
            </CallOut>
            <CenterContent>
              <ToolTip id="assignUsers" activeStepIndex={4} />
            </CenterContent>
          </>
        ) : (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              AssignUserContext.closeAssignUserCallOut()
              setSelectedActivities(activityIDs)
              handleAssignUserDialogOpen(lawarea)
            }}>
            Assign Users
          </Button>
        )}

        <Tooltip title={iconTitle}>
          <PeopleOutline className={iconClass} />
        </Tooltip>
      </CardActions>
    </Card>
  )
}

export default LawareaTile
