import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import LawareaTile from '../LawareaTile'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { LawareaData } from '../ActivityList'
import NoData from './noData'

interface Props {
  lawarea: LawareaData
  handleAssignUserDialogOpen: (lawarea?: string) => void
  setSelectedActivities: (value: React.SetStateAction<React.ReactText[]>) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      padding: 2,
      flexFlow: 'wrap',
    },
    item: {
      margin: '5px',
      width: '190px',
    },
  }),
)

const LawareaGrid: React.FC<Props> = ({
  lawarea,
  handleAssignUserDialogOpen,
  setSelectedActivities,
}) => {
  const classes = useStyles()
  return (
    <div style={{ maxHeight: 400, minHeight: 200, width: '100%', overflow: 'auto' }}>
      {Object.keys(lawarea).length === 0 ? (
        <NoData />
      ) : (
        <List className={classes.root}>
          {Object.keys(lawarea)
            .sort()
            .map((name, index) => (
              <ListItem className={classes.item} key={index}>
                <LawareaTile
                  tileIndex={index}
                  lawarea={name}
                  activityIDs={lawarea[name].IDs}
                  assignedActivityIDs={lawarea[name].assignedIDs}
                  handleAssignUserDialogOpen={handleAssignUserDialogOpen}
                  setSelectedActivities={setSelectedActivities}
                />
              </ListItem>
            ))}
        </List>
      )}
    </div>
  )
}

export default LawareaGrid
