import React, { useContext, useEffect, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Grid } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import TextInput from '../../Components/FormikMuiFields/TextInput'
import MultiSelect from '../../Components/FormikMuiFields/MultiSelect'
import publicFetch from '../../util/publicFetch'
import LoadingButton from '../../Components/LoadingButton'
import { AuthContext } from '../../Context/AuthContext'
import { FetchContext } from '../../Context/FetchContext'
import Skeleton from '../../Components/Skeleton'
import { toast } from 'react-hot-toast'
import { StepWizardContext } from '../../Components/OnboardingSteps'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { onBoardingStepTypes } from '../../Types/onBoardingStepTypes'
import FormCard from '../../Components/FormCard/FormCard'
import Helmet from 'react-helmet'
import SelectInput from '../../Components/FormikMuiFields/SelectInput'
import { company } from '../../Types/company'
import { getFormattedDate } from '../../util/dateUtil'
import { activity } from '../../Types/activity'
import CenterContent from '../../Components/CenterContent'
import ToolTip from '../../Components/ToolTip'
import helperText from '../../data/helperText'

interface Props extends RouteComponentProps<{ step: onBoardingStepTypes }> {}

export interface Sectors {
  id: number
  name: string
}

interface EntityType {
  value: string
  label: string
}

interface SectorActivity {
  value: string
  label: string
}

let validationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  cin: yup.string().required('UEN is a required field'),
  paidup_capital: yup.number(),
  date_of_inc: yup
    .date()
    .nullable()
    .max(new Date(), 'Date should be less than or equals to today.'),
  sector: yup
    .array()
    .min(1, 'Select at least one Sector')
    .of(
      yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.string(),
        })
        .nullable(),
    ),
  industry: yup.array(),
  entity_type: yup.string().required('Entity Type is a required field'),
  sector_activity: yup.string().required('Sector Activity is a required field'),
})

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

const AddCompany: React.FC<Props> = ({ history: { push } }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sectorList, setSectorList] = React.useState<Sectors[]>([])
  const [enabledSectorIDs, setEnabledSectorIDs] = React.useState<number[]>([])
  const [entityTypeList, setEntityTypeList] = React.useState<EntityType[]>([])
  const [sectorActivityList, setSectorActivityList] = React.useState<SectorActivity[]>([])
  const authContext = useContext(AuthContext)
  const fetchContext = useContext(FetchContext)
  const { globalState, dispatch } = useContext(StepWizardContext)
  const currencyList = ['INR', 'SGD', 'USD']

  useEffect(() => {
    const data = helperText.filter((el) => el)
    // @ts-ignore
    dispatch({ type: 'SET_HELPER_MODAL_DATA', payload: data[1]['add-company'] })
  }, [])

  useEffect(() => {
    document.title = 'Onboarding: Add Company'
    if (sectorList.length === 0) {
      publicFetch
        .get(`/api_client_onboard/get_sectors`)
        .then((res) => {
          setSectorList(res.data.result)
          let financialService: Sectors[] = res.data.result.filter(
            (sector: Sectors) => sector.name === 'Financial Services',
          )
          if (financialService.length === 1) {
            setEnabledSectorIDs(financialService.map((f) => f.id))
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    if (entityTypeList.length === 0 && sectorActivityList.length === 0) {
      fetchContext.authAxios
        .post(`/api_client_onboard/get_es_attribute_values`, {
          attributes: {
            'Law Area': ['MAS'],
          },

          filter_attributes: ['Entity Type', 'Sector Activity'],
        })
        .then((res) => {
          const entityTypes: string[] = res.data.result['Entity Type']
          const eTypeList = entityTypes.map((e) => {
            return { value: e, label: e }
          })

          const sectorActivities: string[] = res.data.result['Sector Activity']
          const sActivitiesList = sectorActivities.map((e) => {
            return { value: e, label: e }
          })
          setEntityTypeList(eTypeList)
          setSectorActivityList(sActivitiesList)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    if (!globalState.dataSynced) {
      fetchContext.authAxios
        .get(`/api_client_onboard/get_onboard_info`)
        .then((res) => {
          dispatch({ type: 'SYNC_DATA', payload: res.data[authContext.authState.userInfo.email] })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [authContext, fetchContext])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...globalState.company,
        sector:
          globalState.company.sector.length > 0 ? globalState.company.sector : enabledSectorIDs,
      }}
      validationSchema={validationSchema}
      setErrors
      onSubmit={(values: company, { setErrors }: FormikHelpers<company>) => {
        setIsLoading(true)

        const companyInfo = {
          email: authContext.authState.userInfo.email,
          ...values,
        }

        fetchContext.authAxios
          .post(`/api_client_onboard/company_info`, {
            ...companyInfo,
            date_of_inc: values.date_of_inc ? getFormattedDate(values.date_of_inc) : '',
          })
          .then((res) => {
            dispatch({ type: 'MODIFY_COMPANY', payload: companyInfo })

            if (
              values.entity_type !== globalState.company.entity_type ||
              values.sector_activity !== globalState.company.sector_activity
            ) {
              fetchContext.authAxios
                .post(`/api_client_onboard/onboard_activities`, {
                  'Law Area': ['MAS'],
                  'Sector Activity': [values.sector_activity],
                  'Entity Type': [values.entity_type],
                })
                .then((res) => {
                  let activityList: activity[] = res.data.result.map((activ: activity) => {
                    return { ...activ, id: activ['UAN'] }
                  })
                  dispatch({
                    type: 'SET_MASTER_ACTIVITIES',
                    payload: activityList,
                  })
                  dispatch({
                    type: 'UPDATE_USER_ASSIGNMENT',
                    payload: {},
                  })

                  // Reset location activities with new activity template
                  let activities: activity[]
                  globalState.locations.forEach((location) => {
                    activities = activityList.map((actv) => {
                      return {
                        ...actv,
                        location: location.name,
                        id: actv.UAN + '_' + location.name,
                      }
                    })
                    dispatch({
                      type: 'SET_COMPANY_ACTIVITIES',
                      payload: { location: location.name, activities: activities },
                    })
                  })

                  dispatch({
                    type: 'NEXT_STEP',
                  })
                  setIsLoading(false)
                  toast.success('Company details saved!')
                  if (globalState.company.name && globalState.locations.length !== 0) {
                    toast(
                      'Activities and User assignment is reset because of Entity Type/Sector Activity change.',
                      { duration: 6000 },
                    )
                  }

                  push(`/onboarding/${globalState.workflow}/add-compliances`)
                })
                .catch((error) => {
                  console.log(error)
                })
            } else {
              dispatch({
                type: 'NEXT_STEP',
              })
              setIsLoading(false)
              toast.success('Company details saved!')
              push(`/onboarding/${globalState.workflow}/add-compliances`)
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              setErrors(error.response.data.error)
            } else {
              toast.error('Refresh and try again!')
            }

            setIsLoading(false)
          })
      }}>
      {({ setFieldValue, setFieldTouched, values, touched, errors, isValid, dirty }) => (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Helmet>
            <title>Onboarding: Add Company</title>
          </Helmet>
          <FormCard
            header={
              <CenterContent>
                Add Company <ToolTip id="addCompany" />
              </CenterContent>
            }>
            {sectorList.length === 0 ? (
              <Skeleton skeletonCountArr={[1, 2, 3]} />
            ) : (
              <>
                <Form className={classes.form} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextInput
                        size="medium"
                        name="name"
                        label="Name"
                        autoComplete="off"
                        required
                        error={touched.name && Boolean(errors.name)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        size="medium"
                        name="cin"
                        label="UEN"
                        autoComplete="off"
                        required
                        error={touched.cin && Boolean(errors.cin)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          size="medium"
                          fullWidth
                          inputVariant="outlined"
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          autoOk={true}
                          label="Date of Incorporation"
                          value={values.date_of_inc}
                          onChange={(val) => {
                            setFieldValue('date_of_inc', val)
                            setFieldTouched('date_of_inc', true)
                          }}
                          onBlur={() => {
                            setFieldTouched('date_of_inc', true)
                          }}
                          openTo="year"
                          views={['year', 'month', 'date']}
                          error={touched.date_of_inc && Boolean(errors.date_of_inc)}
                          helperText={touched.date_of_inc ? errors.date_of_inc : ''}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        error={Boolean(touched.entity_type) && Boolean(errors.entity_type)}
                        name="entity_type"
                        label="Entity Type"
                        fullWidth={true}
                        items={entityTypeList}
                      />
                    </Grid>
                    <Grid container item xs={12} sm={6}>
                      <Grid item xs>
                        <SelectInput
                          name="puc_currency"
                          label="Currency"
                          items={currencyList.map((c) => {
                            return { value: c, label: c }
                          })}
                          required={false}
                          fullWidth={false}
                          error={false}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextInput
                          size="medium"
                          name="paidup_capital"
                          label="Paid-Up Capital"
                          type="number"
                          margin="normal"
                          autoComplete="off"
                          fullWidth={false}
                          error={touched.paidup_capital && Boolean(errors.paidup_capital)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MultiSelect
                        name="sector"
                        label="Sector"
                        items={sectorList}
                        disableOtherThanThis={enabledSectorIDs}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        error={Boolean(touched.sector_activity) && Boolean(errors.sector_activity)}
                        name="sector_activity"
                        label="Sector Activity"
                        fullWidth={true}
                        items={sectorActivityList}
                      />
                    </Grid>
                  </Grid>
                  <LoadingButton
                    loading={isLoading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.submit}
                    disabled={
                      dirty
                        ? isValid
                          ? isLoading
                          : true
                        : globalState.company.name
                        ? isLoading
                        : true
                    }>
                    Submit
                  </LoadingButton>
                </Form>
              </>
            )}
          </FormCard>
        </Container>
      )}
    </Formik>
  )
}

export default withRouter(AddCompany)
