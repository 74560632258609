import {
  cmpInfo,
  questionnaire,
  StepWizardActionTypes,
  StepWizardInitialStateType,
} from '../Types/stepWizardTypes'
import { activity, CompanyActivities } from '../Types/activity'
import { user } from '../Types/user'
import { Location } from '../Types/location'
import { ActivityMapping } from '../Types/activityMapping'
import { company } from '../Types/company'
import { companyDefault, initialState } from '../Components/OnboardingSteps'

import { Question } from '../Components/Questionnaire/types'
import { getActiveQuestion } from '../Components/Questionnaire/helper'

const formBuilderReducer = (state: StepWizardInitialStateType, action: StepWizardActionTypes) => {
  switch (action.type) {
    case 'SUCCESS_LOGIN': {
      return {
        ...state,
        OTPVerified: action.payload.OTPVerified,
        loginStep: action.payload.loginStep,
        stepCount: 1,
      }
    }
    case 'NEXT_STEP': {
      return {
        ...state,
        stepCount: state.stepCount + 1,
      }
    }
    case 'PREV_STEP': {
      return {
        ...state,
        stepCount: state.stepCount - 1,
      }
    }
    case 'SET_STEP': {
      return {
        ...state,
        stepCount: action.payload,
      }
    }
    case 'ADD_LOGIN_DETAILS': {
      return {
        ...state,
        loginStep: action.payload,
      }
    }
    case 'ERROR_LOGIN': {
      return { ...state, OTPVerified: false }
    }
    case 'MODIFY_COMPANY': {
      return { ...state, company: action.payload }
    }
    case 'MODIFY_QUESTIONNAIRE': {
      return { ...state, questionnaire: action.payload }
    }
    case 'MODIFY_LOCATION': {
      const oldLocName: string = action.payload.oldLocationName
      let updatedLocationList = []
      let locList: Location[]
      if (state.locations !== null) {
        locList = [...state.locations]
      } else {
        locList = []
      }
      let prevCompanyActivities: CompanyActivities = { ...state.companyActivities }
      let prevAssignment: ActivityMapping = state.activityMapping
      if (oldLocName) {
        const index: number = locList.findIndex((loc) => loc.name === oldLocName)
        updatedLocationList = [...locList]
        updatedLocationList[index] = action.payload.newLocation
        let prevLocationActivities: activity[] = state.companyActivities[oldLocName]
        prevLocationActivities = prevLocationActivities.map((activity) => {
          return { ...activity, location: action.payload.newLocation.name }
        })
        prevCompanyActivities[action.payload.newLocation.name] = prevLocationActivities
        delete prevCompanyActivities[oldLocName]

        // Update assignment
        if (oldLocName in prevAssignment) {
          prevAssignment[action.payload.newLocation.name] = prevAssignment[oldLocName]
        }
        delete prevAssignment[oldLocName]
      } else {
        updatedLocationList = [...locList, action.payload.newLocation]
      }
      return {
        ...state,
        locations: updatedLocationList,
        companyActivities: prevCompanyActivities,
        activityMapping: prevAssignment,
      }
    }
    case 'SET_DEFAULT_VALUES': {
      return { ...state, defaultValues: action.payload }
    }
    case 'ADD_USER': {
      return { ...state, users: [...state.users, ...action.payload] }
    }
    case 'SET_MASTER_ACTIVITIES': {
      return { ...state, masterActivityList: action.payload }
    }
    case 'SET_COMPANY_ACTIVITIES': {
      return {
        ...state,
        companyActivities: {
          ...state.companyActivities,
          [action.payload.location]: action.payload.activities,
        },
      }
    }
    case 'UPDATE_USER_ASSIGNMENT': {
      return { ...state, activityMapping: action.payload }
    }
    case 'SYNC_DATA': {
      let companyInfo: company = companyDefault
      let questionnaireObj: questionnaire = {}
      if (state.workflow.toLowerCase() === 'mas') {
        if ('cmp_info' in action.payload) {
          const compInfo: cmpInfo = action.payload['cmp_info'] as cmpInfo
          companyInfo = {
            ...compInfo,
            sector: compInfo['sector']
              ? compInfo['sector'].map((sector) => {
                  return sector['id']
                })
              : [],
            date_of_inc: compInfo['date_of_inc'] ? new Date(compInfo['date_of_inc']) : null,
          }
        }
      } else {
        if ('cmp_info' in action.payload) {
          questionnaireObj = action.payload['cmp_info'] as questionnaire
        }
      }

      let userList: user[] = []
      if ('user_info' in action.payload) {
        Object.keys(action.payload['user_info']).forEach(function (key) {
          userList = [...userList, action.payload['user_info'][key]]
        })
      }
      let locationList: Location[] = []
      if ('loc_info' in action.payload) {
        Object.keys(action.payload['loc_info']).forEach(function (key) {
          locationList = [...locationList, action.payload['loc_info'][key]]
        })
      }
      let activityList: activity[] = []
      if ('activities' in action.payload) {
        activityList = action.payload['activities']
      }

      let companyActivities: CompanyActivities = {}
      if ('loc_info' in action.payload) {
        Object.keys(action.payload['loc_info']).forEach(function (loc) {
          let tempActivities: activity[]
          tempActivities = activityList.map((actv) => {
            return { ...actv, location: loc, id: actv.UAN + '_' + loc }
          })
          companyActivities[loc] = tempActivities
        })
      }
      let activityMapping: ActivityMapping = {}
      if ('activity_mapping' in action.payload) {
        activityMapping = action.payload['activity_mapping']
      }

      return {
        ...state,
        users: userList,
        locations: locationList,
        masterActivityList: activityList,
        companyActivities: companyActivities,
        activityMapping: activityMapping,
        company: companyInfo,
        questionnaire: questionnaireObj,
        dataSynced: true,
      }
    }
    case 'RESET': {
      return initialState
    }
    case 'UPDATE_HELPER_MODAL': {
      return {
        ...state,
        isHelperModalOpen: action.payload,
      }
    }
    case 'SET_HELPER_TEXT': {
      return {
        ...state,
        isHelperModalOpen: action.payload.openModal,
        activeStep: action.payload.activeStep,
      }
    }
    case 'UPDATE_ACTIVE_STEP_HELPER_MODAL': {
      return {
        ...state,
        activeStep: action.payload,
      }
    }
    case 'SET_HELPER_MODAL_DATA': {
      return {
        ...state,
        helperTextData: action.payload,
      }
    }
    case 'SET_NOTIFICATIONS': {
      return {
        ...state,
        notification: action.payload,
      }
    }
    case 'SET_QUESTIONS': {
      const questions: Question[] = action.payload.map((question: Question) => {
        return {
          type: question.type,
          mandatory: question.mandatory,
          question: question.question,
          name: question.name,
          placeholder: question.placeholder ? question.placeholder : '',
          id: question.id,
          value: question.value ? question.value : '',
          options: question.options ? question.options : [],
          quick_fill: question.quick_fill
            ? Object.keys(question.quick_fill).length
              ? question.quick_fill
              : null
            : null,
          title: question.title,
          description: question.description,
          readmore: question.readmore,
          help_text: question.help_text,
        }
      })
      if (questions.length > 0) {
        questions[0].active = true
      }
      return {
        ...state,
        questionList: questions,
      }
    }
    case 'PREVIOUS_QUESTION': {
      const tempQList: Question[] = [...state.questionList]
      const index: number = tempQList.findIndex((question) => question.active)
      if (index - 1 >= 0) {
        tempQList[index] = { ...tempQList[index], active: false }
        tempQList[index - 1] = { ...tempQList[index - 1], active: true }
      }
      return {
        ...state,
        questionList: tempQList,
      }
    }
    case 'NEXT_QUESTION': {
      const tempQList: Question[] = [...state.questionList]
      const activeQuestion: Question = getActiveQuestion(tempQList)
      const index: number = tempQList.findIndex((question) => question.active)
      // If answer changed for question then remove all previously added next question
      // debugger
      if (action.payload.removeNextQuestions) {
        tempQList.length = index + 1
      }

      // append new questions
      action.payload.nextQuestions.forEach((question) => tempQList.push(question))
      tempQList[index] = {
        ...tempQList[index],
        value: action.payload.submittedVals[activeQuestion.name],
        active: false,
      }
      tempQList[index + 1] = { ...tempQList[index + 1], active: true }
      return {
        ...state,
        questionList: tempQList,
      }
    }
    case 'END_QUESTION': {
      const tempQList: Question[] = [...state.questionList]
      const activeQuestion: Question = getActiveQuestion(tempQList)
      const index: number = tempQList.findIndex((question) => question.active)
      tempQList.length = index + 1
      tempQList[index] = {
        ...tempQList[index],
        value: action.payload.submittedVals[activeQuestion.name],
      }
      return {
        ...state,
        questionList: tempQList,
      }
    }
    default:
      return state
  }
}
export default formBuilderReducer
