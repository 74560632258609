import React, { useState } from 'react'
import LocationList from '../../Components/LocationList'
import { makeStyles } from '@material-ui/core/styles'
import ActivityList from '../../Components/ActivityList'
import { AuthContext } from '../../Context/AuthContext'
import { FetchContext } from '../../Context/FetchContext'
import { StepWizardContext } from '../../Components/OnboardingSteps'
import Helmet from 'react-helmet'
import { useContext } from 'react'
import { useEffect } from 'react'
import SeniorManagerTab from '../../Components/SeniorManagerTab'
import { Button, Tooltip } from '@material-ui/core'
import { onBoardingStepTypes } from '../../Types/onBoardingStepTypes'
import { RouteComponentProps } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import helperText from '../../data/helperText'
import { title } from 'process'
import { getUsersByRole } from '../../util/user'
import Skeleton from '../../Components/Skeleton'
import toast from 'react-hot-toast'
import { getIDForName, getUniqueLawareas, isAnyActivityAssigned } from '../../util/common'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import ViewListIcon from '@material-ui/icons/ViewList'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import { AssignUserCallOutProvider } from '../../Context/AssignUserCallOut'

interface Props extends RouteComponentProps<{ step: onBoardingStepTypes }> {}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: 'fill-available',
    padding: '50px',
  },
  left: {
    flex: 1,
    marginRight: '10px',
  },
  right: {
    flex: 2.5,
  },
})

const AddCompliances: React.FC<Props> = ({ history: { push } }) => {
  const classes = useStyles()
  const [selectedActivities, setSelectedActivities] = React.useState<(string | number)[]>([])
  const authContext = useContext(AuthContext)
  const fetchContext = useContext(FetchContext)
  const { globalState, dispatch } = useContext(StepWizardContext)
  let uniqueLawareas: string[] = getUniqueLawareas(globalState.masterActivityList)
  const [view, setView] = useState<string | null>(
    uniqueLawareas.length > 1 ? 'lawarea' : 'activity',
  )

  const handleView = (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
    if (newView !== null) {
      setView(newView)
      setSelectedActivities([])
    }
  }

  const ViewToggle = (props: {}) => {
    return (
      <ToggleButtonGroup
        size={'small'}
        value={view}
        exclusive
        onChange={handleView}
        aria-label="Set View">
        <ToggleButton value="lawarea" aria-label="Law Area">
          <Tooltip title="Law Area View" placement="top">
            <ViewModuleIcon />
          </Tooltip>
        </ToggleButton>

        <ToggleButton value="activity" aria-label="Activity">
          <Tooltip title="Activity View" placement="top">
            <ViewListIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }

  useEffect(() => {
    const data = helperText.filter((el) => el)
    // @ts-ignore
    dispatch({ type: 'SET_HELPER_MODAL_DATA', payload: data[2]['add-compliances'] })
  }, [])

  useEffect(() => {
    if (!globalState.defaultValues.set) {
      fetchContext.authAxios
        .get(`/api_client_onboard/get_states`)
        .then((res) => {
          let stateRec: { [key: string]: string } = getIDForName(res.data.result, 'Singapore')
          if (stateRec) {
            fetchContext.authAxios
              .get(`/api_client_onboard/get_cities`)
              .then((res) => {
                let city: { [key: string]: string } = getIDForName(res.data.result, 'Singapore')

                dispatch({
                  type: 'SET_DEFAULT_VALUES',
                  payload: {
                    set: true,
                    country: stateRec.country || '',
                    state: stateRec.id || '',
                    city: city.id || '',
                  },
                })
              })
              .catch((error) => {
                //
              })
          }
        })
        .catch((error) => {
          //
        })
    }
    if (!globalState.dataSynced) {
      fetchContext.authAxios
        .get(`/api_client_onboard/get_onboard_info`)
        .then((res) => {
          dispatch({ type: 'SYNC_DATA', payload: res.data[authContext.authState.userInfo.email] })

          if ('cmp_info' in res.data[authContext.authState.userInfo.email]) {
            if (
              (globalState.workflow.toLowerCase() === 'mas' &&
                !('name' in res.data[authContext.authState.userInfo.email].cmp_info)) ||
              (globalState.workflow.toLowerCase() !== 'mas' &&
                Object.keys(res.data[authContext.authState.userInfo.email].cmp_info).length ===
                  0) ||
              (globalState.workflow.toLowerCase() !== 'mas' &&
                (!res.data[authContext.authState.userInfo.email].loc_info ||
                  Object.keys(res.data[authContext.authState.userInfo.email].loc_info).length ===
                    0))
            ) {
              push(`/onboarding/${globalState.workflow}/add-company`)
            }
          } else {
            push(`/onboarding/${globalState.workflow}/add-company`)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  const disableProceedButton = () => {
    const seniorManagers: {
      value: string
      label: string
    }[] = getUsersByRole(globalState.users, 'senior_manager')

    let seniorManagersExist: boolean = seniorManagers.length > 0 ? true : false
    return seniorManagersExist && isAnyActivityAssigned(globalState.activityMapping)
  }

  let disableProceed: boolean = disableProceedButton()
  return (
    <div className={classes.container} style={{ width: '-moz-available' }}>
      <Helmet>
        <title>Onboarding: Add Compliances</title>
      </Helmet>

      {!globalState.dataSynced || !globalState.defaultValues.set ? (
        <Skeleton skeletonCountArr={[1, 2, 3]} />
      ) : (
        <>
          <div className={classes.left}>
            {uniqueLawareas.length > 1 && <ViewToggle />}
            <LocationList
              selectedActivities={selectedActivities}
              setSelectedActivities={setSelectedActivities}
            />
            <SeniorManagerTab />
          </div>
          <div className={classes.right}>
            <AssignUserCallOutProvider>
              <ActivityList
                selectedActivities={selectedActivities}
                setSelectedActivities={setSelectedActivities}
                view={view}
              />
            </AssignUserCallOutProvider>
            <Grid justify="center" container spacing={2}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    style={{ margin: '10px' }}
                    variant="text"
                    color="secondary"
                    onClick={() => {
                      dispatch({
                        type: 'PREV_STEP',
                      })
                      push(`/onboarding/${globalState.workflow}/add-company`)
                    }}>
                    Back
                  </Button>
                </div>
                <div
                  onClick={() => {
                    if (!disableProceed) {
                      toast.error(
                        'Assign at least one activity and add at least one Senior Manager to proceed',
                        { duration: 4000 },
                      )
                    }
                  }}>
                  <Button
                    disabled={!disableProceed}
                    style={{ pointerEvents: 'auto' }}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      dispatch({
                        type: 'NEXT_STEP',
                      })
                      push(`/onboarding/${globalState.workflow}/review-onboarding`)
                    }}>
                    Proceed
                  </Button>
                </div>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  )
}

export default AddCompliances
