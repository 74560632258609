import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AuthProvider } from '../../Context/AuthContext'
import OnBoardingSteps from '../OnboardingSteps'
import { Toaster } from 'react-hot-toast'
import Header from '../Header/Header'
import { FetchProvider } from '../../Context/FetchContext'
import ScrollToTop from '../ScrollToTop'
import FourOFour from '../../Pages/404'
import Home from '../../Pages/Home'

const App: React.FC = () => {
  const pathname = window.location.pathname
  const workflow = pathname.split('/').length > 2 ? pathname.split('/')[2] : ''
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider workflow={workflow}>
        <FetchProvider>
          <div className="App">
            <Toaster toastOptions={{ style: { fontFamily: 'Roboto' } }} />
            <Header />
            <Switch>
              <Route
                exact
                path="/"
                render={() => (window.location.href = 'https://quantlegaltech.com/')}
              />
              <Route path="/onboarding/:workflow/:page?" component={OnBoardingSteps} />
              <Route render={() => (window.location.href = 'https://quantlegaltech.com/')} />
            </Switch>
          </div>
        </FetchProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
