import React, { ReactNode } from 'react'
import { Field, ErrorMessage, FieldInputProps } from 'formik'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core'

export interface RadioItem {
  label: string
  value: string
}

interface Props {
  name: string
  items: RadioItem[]
  error?: boolean
}

interface RadioMuiProps extends FieldInputProps<string> {
  children: ReactNode
  error: boolean
  errorText: string
}

const RadioMui: React.FC<RadioMuiProps> = ({
  children,
  value,
  name,
  error,
  onChange,
  onBlur,
  errorText,
}) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup value={value ? value : ''} onChange={onChange} onBlur={onBlur} name={name}>
        {children}
      </RadioGroup>
      <FormHelperText>{error ? errorText : ''}</FormHelperText>
    </FormControl>
  )
}

const RadioInput: React.FC<Props & RadioGroupProps> = ({ name, items, error = false, ...rest }) => {
  return (
    <Field
      as={RadioMui}
      name={name}
      error={error}
      errorText={<ErrorMessage name={name} />}
      {...rest}>
      {items.map((item: RadioItem) => (
        <FormControlLabel
          value={item.value}
          control={<Radio color="primary" />}
          label={item.label}
          key={item.value}
        />
      ))}
    </Field>
  )
}

export default RadioInput
