import { AxiosInstance } from 'axios'
import { FormikHelpers } from 'formik'
import { toast } from 'react-hot-toast'
import { activity } from '../../Types/activity'
import { StepWizardActionTypes, StepWizardInitialStateType } from '../../Types/stepWizardTypes'
import { isEmpty } from '../../util/common'
import { appendAndMoveNext, isActiveQuestionLast } from './helper'
import { SlideProps, Question, initialVals } from './types'
import { History } from 'history'
import { Location } from '../../Types/location'
import { ContextProps } from '../../Context/SelectedLocations'
import { OfficeType } from '../LocationFormDialog'

export const backHandler = (
  currentQuestion: Question,
  setSlideIn: React.Dispatch<React.SetStateAction<boolean>>,
  setSlideDirection: React.Dispatch<React.SetStateAction<SlideProps>>,
  dispatch: React.Dispatch<StepWizardActionTypes>,
) => {
  setSlideIn(false)
  setSlideDirection('left')
  setTimeout(() => {
    setSlideDirection('right')
    setSlideIn(true)
    dispatch({ type: 'PREVIOUS_QUESTION' })
  }, 500)
}

export const submitHandler = (
  fetchContext: {
    authAxios: AxiosInstance
  },

  globalState: StepWizardInitialStateType,
  dispatch: React.Dispatch<StepWizardActionTypes>,
  history: History<unknown>,
  activeQuestion: Question,
  values: initialVals,
  setSlideIn: React.Dispatch<React.SetStateAction<boolean>>,
  setSlideDirection: React.Dispatch<React.SetStateAction<SlideProps>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  { setFieldError }: FormikHelpers<initialVals>,
  LocationContext: ContextProps,
) => {
  const questionList: Question[] = globalState.questionList
  var submittedVals = {
    workflow: globalState.workflow,
    version: 'Free',
    id: activeQuestion.id,
    name: activeQuestion.name,
    value: values[activeQuestion.name],
  }
  if (
    activeQuestion.value !== values[activeQuestion.name] ||
    (!activeQuestion.mandatory &&
      isEmpty(values[activeQuestion.name]) &&
      isActiveQuestionLast(questionList))
  ) {
    setIsLoading(true)
    fetchContext.authAxios
      .post(`/api_client_onboard/save_answer_to_redis`, submittedVals)
      .then((res) => {
        fetchContext.authAxios
          .post(`/api_client_onboard/get_next_question`, submittedVals)
          .then((res) => {
            setIsLoading(false)
            if (res.data.result.length) {
              appendAndMoveNext(
                res.data.result,
                activeQuestion,
                dispatch,
                values,
                setSlideIn,
                setSlideDirection,
                setIsLoading,
                true,
              )
            } else {
              toast.success('All responses saved successfully!')
              dispatch({ type: 'END_QUESTION', payload: { submittedVals: values } })
              getApplicableActivities(
                fetchContext,
                history,
                globalState,
                dispatch,
                setIsLoading,
                LocationContext,
              )
            }
          })
          .catch((error) => {
            setIsLoading(false)
            toast.error('Something went wrong')
          })
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          setFieldError(activeQuestion.name, error.response.data.error)
        } else {
          toast.error('Something went wrong')
        }
      })
  } else if (isActiveQuestionLast(questionList)) {
    setIsLoading(true)
    fetchContext.authAxios
      .post(`/api_client_onboard/get_next_question`, submittedVals)
      .then((res) => {
        if (res.data.result.length) {
          appendAndMoveNext(
            res.data.result,
            activeQuestion,
            dispatch,
            values,
            setSlideIn,
            setSlideDirection,
            setIsLoading,
            true,
          )
          setIsLoading(false)
        } else {
          getApplicableActivities(
            fetchContext,
            history,
            globalState,
            dispatch,
            setIsLoading,
            LocationContext,
          )
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error('Something went wrong')
      })
  } else {
    appendAndMoveNext(
      [],
      activeQuestion,
      dispatch,
      values,
      setSlideIn,
      setSlideDirection,
      setIsLoading,
      false,
    )
  }
}

const getApplicableActivities = (
  fetchContext: {
    authAxios: AxiosInstance
  },
  history: History<unknown>,
  globalState: StepWizardInitialStateType,
  dispatch: React.Dispatch<StepWizardActionTypes>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  LocationContext: ContextProps,
) => {
  const questionList: Question[] = globalState.questionList
  setIsLoading(true)
  fetchContext.authAxios
    .post(`/api_client_onboard/onboard_activities`, {
      workflow: globalState.workflow,
      version: 'Free',
    })
    .then((res) => {
      let activityList: activity[] = res.data.result.map((activ: activity) => {
        return { ...activ, id: activ['UAN'] }
      })
      dispatch({
        type: 'SET_MASTER_ACTIVITIES',
        payload: activityList,
      })
      dispatch({
        type: 'UPDATE_USER_ASSIGNMENT',
        payload: {},
      })

      // Reset location activities with new activity template
      let activities: activity[]
      globalState.locations.forEach((location) => {
        activities = activityList.map((actv) => {
          return {
            ...actv,
            location: location.name,
            id: actv.UAN + '_' + location.name,
          }
        })
        dispatch({
          type: 'SET_COMPANY_ACTIVITIES',
          payload: { location: location.name, activities: activities },
        })
      })

      if (globalState.company.name && globalState.locations.length !== 0) {
        toast('Activities and User assignment is reset because of changes in answers', {
          duration: 6000,
        })
      }

      // if (globalState.locations.length === 0) {
      fetchContext.authAxios
        .post(`/api_client_onboard/onboard_india_location`)
        .then((res) => {
          const defaultLocation: Location = {
            name: res.data.name,
            address: res.data.address,
            office_type: res.data.office_type,
            country: res.data.country.id,
            state: res.data.state.id,
            city: res.data.city.id,
          }
          dispatch({
            type: 'MODIFY_LOCATION',
            payload: {
              oldLocationName:
                globalState.locations.length === 1 ? globalState.locations[0].name : '',
              newLocation: defaultLocation,
            },
          })
          let tempActivities: activity[] = activityList
          tempActivities = tempActivities.map((actv) => {
            return {
              ...actv,
              location: defaultLocation.name,
              id: actv.UAN + '_' + defaultLocation.name,
            }
          })

          dispatch({
            type: 'SET_COMPANY_ACTIVITIES',
            payload: { location: defaultLocation.name, activities: tempActivities },
          })

          LocationContext.setSelectedLocations([
            ...LocationContext.selectedLocations,
            defaultLocation.name,
          ])
          history.push(`/onboarding/${globalState.workflow}/add-compliances`)
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
        })
    })
    .catch((error) => {
      setIsLoading(false)
      console.log(error)
      toast.error('Failed to get Applicable Activities')
    })
}
