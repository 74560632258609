import React from 'react'
import TextField from '@material-ui/core/TextField'
import { TextFieldProps } from '@material-ui/core/TextField'
import { Field, ErrorMessage } from 'formik'

interface Props {
  name: string
  fullWidth?: Boolean
  margin?: string
  variant?: string
}

const TextInput: React.FC<Props & TextFieldProps> = ({
  name,
  fullWidth = true,
  margin = 'normal',
  variant = 'outlined',
  ...rest
}) => {
  return (
    <Field
      as={TextField}
      name={name}
      margin={margin}
      variant={variant}
      fullWidth={fullWidth}
      helperText={rest.error ? <ErrorMessage name={name} /> : ''}
      {...rest}
    />
  )
}

export default TextInput
